<template>
    <div class="box w-28 flex-none  flex items-center justify-center ml-1">
        <div class="text-center">
            <span class="text-lg">Total</span>
            <div class="text-3xl mt-2">{{ row.total.oportunity | numberFormat() }}</div>
            <div class="text-font-gray">{{ row.total.sales | reduceBigNumbers(2) }}€</div>
        </div>
    </div>
</template>

<script>
    export default {
        name: "Totals",
        props: ['row']
    }
</script>